<template>
	<div>
		<!-- <pre style="max-height: 10rem">{{ rows }}</pre> -->
		<b-overlay :show="isLoading">
			<b-row class="mb-1">
				<b-col cols="12" md="6" class="d-flex align-items-center mb-1 mb-md-0">
					<b-tabs v-model="status" @input="getRows">
						<b-tab>
							<template #title>
								EN ESPERA
								<b-badge
									class="ml-05"
									variant="danger"
									v-if="counters.chicks && counters.chicks[0].counter != 0"
								>
									{{ counters.chicks[0].counter }}
								</b-badge>
							</template>
						</b-tab>
						<b-tab>
							<template #title>
								EN PROCESO
								<b-badge
									class="ml-05"
									variant="danger"
									v-if="counters.chicks && counters.chicks[1].counter != 0"
								>
									{{ counters.chicks[1].counter }}
								</b-badge>
							</template>
						</b-tab>
						<b-tab>
							<template #title>
								FINALIZADOS
								<!-- <b-badge
									class="ml-05"
									variant="danger"
									v-if="counters.chicks && counters.chicks[2].counter != 0"
								>
									{{ counters.chicks[2].counter }}
								</b-badge> -->
							</template>
						</b-tab>
						<b-tab>
							<template #title>CANCELADOS</template>
						</b-tab>
					</b-tabs>
				</b-col>
			</b-row>
			<b-row class="mb-1">
				<b-col cols="12" md="6" class="d-flex align-items-center mb-1 mb-md-0">
					<span>
						Mostrando {{ pagination.from }} a {{ pagination.to }} de {{ pagination.total }} registros
					</span>
				</b-col>
				<b-col cols="12" md="6" class="d-flex justify-content-md-end">
					<b-pagination
						class="m-0"
						pills
						hide-goto-end-buttons
						hide-ellipsis
						v-model="pagination.page"
						:total-rows="pagination.total"
						:per-page="pagination.perPage"
						@input="getRows"
					/>
				</b-col>
			</b-row>
			<b-row class="mb-2">
				<b-col cols="12" md="6" class="d-flex align-items-center gap-x-2 mb-1 mb-md-0">
					<span>Mostrar</span>
					<b-form-select
						style="width: 5rem"
						:options="[20, 50, 100, 200]"
						v-model="pagination.perPage"
						@input="getRows"
					/>
					<span>registros</span>
					<b-button class="btn-icon" size="sm" variant="flat-secondary" @click="resetRows">
						<feather-icon icon="RefreshCcwIcon" />
					</b-button>
				</b-col>
				<b-col cols="12" md="2"></b-col>
				<b-col cols="12" md="4">
					<b-input-group>
						<b-form-input placeholder="Buscar cintillo" v-model="search" @keypress.enter="getRows" />
						<b-input-group-append>
							<b-button class="btn-icon" variant="primary" @click="getRows">
								<feather-icon icon="SearchIcon" />
							</b-button>
						</b-input-group-append>
					</b-input-group>
				</b-col>
			</b-row>
			<b-table-simple sticky-header="70vh">
				<b-thead class="text-center text-nowrap">
					<b-tr>
						<b-th>
							<span @click="changeOrder(1)">
								Pollo
								<template v-if="orderBy == 1">
									<feather-icon
										:class="[orderBy != 1 && 'text-muted', 'ml-05']"
										icon="ChevronUpIcon"
										v-if="order == 'asc'"
									/>
									<feather-icon
										:class="[orderBy != 1 && 'text-muted', 'ml-05']"
										icon="ChevronDownIcon"
										v-if="order == 'desc'"
									/>
								</template>
								<feather-icon class="text-muted ml-05" icon="MinusIcon" v-else />
							</span>
						</b-th>
						<b-th v-if="[2, 3].includes(status)">RESULTADO</b-th>
						<b-th>
							<span>
								Razones
								<!-- <template v-if="orderBy == 2">
									<feather-icon
										:class="[orderBy != 2 && 'text-muted', 'ml-05']"
										icon="ChevronUpIcon"
										v-if="order == 'asc'"
									/>
									<feather-icon
										:class="[orderBy != 2 && 'text-muted', 'ml-05']"
										icon="ChevronDownIcon"
										v-if="order == 'desc'"
									/>
								</template>
								<feather-icon class="text-muted ml-05" icon="MinusIcon" v-else /> -->
							</span>
						</b-th>
						<b-th v-if="![0, 3].includes(status)">
							<span @click="changeOrder(2)">
								Diagnosticos
								<template v-if="orderBy == 2">
									<feather-icon
										:class="[orderBy != 2 && 'text-muted', 'ml-05']"
										icon="ChevronUpIcon"
										v-if="order == 'asc'"
									/>
									<feather-icon
										:class="[orderBy != 2 && 'text-muted', 'ml-05']"
										icon="ChevronDownIcon"
										v-if="order == 'desc'"
									/>
								</template>
								<feather-icon class="text-muted ml-05" icon="MinusIcon" v-else />
							</span>
						</b-th>
						<b-th v-if="![0, 3].includes(status)">
							<span @click="changeOrder(3)">
								Tratamientos
								<template v-if="orderBy == 3">
									<feather-icon
										:class="[orderBy != 3 && 'text-muted', 'ml-05']"
										icon="ChevronUpIcon"
										v-if="order == 'asc'"
									/>
									<feather-icon
										:class="[orderBy != 3 && 'text-muted', 'ml-05']"
										icon="ChevronDownIcon"
										v-if="order == 'desc'"
									/>
								</template>
								<feather-icon class="text-muted ml-05" icon="MinusIcon" v-else />
							</span>
						</b-th>
						<b-th>
							<span @click="changeOrder(4)">
								Ingresado por
								<template v-if="orderBy == 4">
									<feather-icon
										:class="[orderBy != 4 && 'text-muted', 'ml-05']"
										icon="ChevronUpIcon"
										v-if="order == 'asc'"
									/>
									<feather-icon
										:class="[orderBy != 4 && 'text-muted', 'ml-05']"
										icon="ChevronDownIcon"
										v-if="order == 'desc'"
									/>
								</template>
								<feather-icon class="text-muted ml-05" icon="MinusIcon" v-else />
							</span>
						</b-th>
						<b-th v-if="status != 0">
							<span @click="changeOrder(5)">
								Actualizado por
								<template v-if="orderBy == 5">
									<feather-icon
										:class="[orderBy != 5 && 'text-muted', 'ml-05']"
										icon="ChevronUpIcon"
										v-if="order == 'asc'"
									/>
									<feather-icon
										:class="[orderBy != 5 && 'text-muted', 'ml-05']"
										icon="ChevronDownIcon"
										v-if="order == 'desc'"
									/>
								</template>
								<feather-icon class="text-muted ml-05" icon="MinusIcon" v-else />
							</span>
						</b-th>
						<b-th>Acciones</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="(row, idx) in rows" :key="idx">
						<b-td>
							<b-badge variant="primary">
								{{ row.chick.correlative }}
							</b-badge>
						</b-td>
						<b-td v-if="[2, 3].includes(status)">
							<div
								v-if="row.is_dead"
								v-b-tooltip.hover
								title="DE BAJA"
								class="d-flex justify-content-center"
							>
								<div style="width: 15px; height: 15px; border-radius: 50%; background: red"></div>
							</div>
							<div v-else v-b-tooltip.hover title="DE ALTA" class="d-flex justify-content-center">
								<div style="width: 15px; height: 15px; border-radius: 50%; background: green"></div>
							</div>
						</b-td>
						<b-td>
							<div class="d-flex flex-column align-items-start gap-y-1 text-left">
								<ul>
									<small
										v-for="(reason, idx) in row.reasons"
										:key="idx"
										variant=""
										class="d-flex align-items-center"
									>
										<li>{{ reason }}.</li>
									</small>
								</ul>
							</div>
						</b-td>
						<b-td v-if="[1, 2].includes(status)">
							<div :class="row.diagnostics.length > 0 ? 'd-flex align-items-center gap-x-2' : ''">
								<div
									class="d-flex flex-column align-items-start gap-y-1 text-left"
									v-if="row.diagnostics.length != 0"
								>
									<ul>
										<small v-for="(diagnostic, idx) in row.diagnostics" :key="idx">
											<li>{{ diagnostic }}.</li>
										</small>
									</ul>
								</div>
								<span
									@click="
										;(manageDiagnostic.info = { ...row, _readonly: status != 1 }),
											(manageDiagnostic.show = !manageDiagnostic.show)
									"
									class="cursor-pointer"
								>
									<feather-icon icon="Edit3Icon" size="20" v-if="status == 1" />
									<!-- <feather-icon icon="EyeIcon" size="20" v-else /> -->
								</span>
							</div>
						</b-td>
						<b-td v-if="[1, 2].includes(status)">
							<div :class="row.treatments.length > 0 ? 'd-flex align-items-center gap-x-2' : ''">
								<div class="text-left" v-if="row.treatments.length != 0">
									<ul>
										<small v-for="(treatment, idx) in row.treatments" :key="idx">
											<li>{{ treatment }}.</li>
										</small>
									</ul>
								</div>
								<span
									@click="
										;(manageTreatment.info = { ...row, _readonly: status != 1 }),
											(manageTreatment.show = !manageTreatment.show)
									"
									class="cursor-pointer"
								>
									<feather-icon icon="Edit3Icon" size="20" v-if="status == 1" />
									<!-- <feather-icon icon="EyeIcon" size="20" v-else /> -->
								</span>
							</div>
						</b-td>
						<b-td>
							<span>{{ row.created_by.name }}</span>
							<br />
							<small>{{ row.created_by.date | myGlobalDayWithHour }}</small>
						</b-td>
						<b-td v-if="status != 0">
							<span>{{ row.updated_by.name }}</span>
							<br />
							<small>{{ row.updated_by.date | myGlobalDayWithHour }}</small>
						</b-td>
						<b-td>
							<div class="d-flex gap-x-2 justify-content-center align-items-center">
								<b-button
									@click="accept(row)"
									v-if="status == 0"
									v-b-tooltip.hover
									title="ENVIAR A EN PROCESO"
									class="btn-icon p-50"
									variant="outline-primary"
								>
									<v-icon
										name="la-user-nurse-solid"
										scale="1"
										class="p-0 cursor-pointer text-primary"
									/>
								</b-button>

								<b-button
									class="btn-icon"
									variant="outline-warning"
									@click=";(finish.info = row), (finish.show = !finish.show)"
									v-if="status == 1"
									v-b-tooltip.hover
									title="FINALIZAR"
								>
									<feather-icon icon="CheckIcon" />
								</b-button>

								<b-button
									v-if="status == 0"
									class="btn-icon"
									variant="outline-warning"
									@click=";(cancel.info = row), (cancel.show = !cancel.show)"
									v-b-tooltip.hover
									title="CANCELAR"
								>
									<feather-icon icon="XIcon" />
								</b-button>

								<b-button
									class="btn-icon"
									variant="outline-secondary"
									@click=";(tracking.info = row), (tracking.show = !tracking.show)"
								>
									<feather-icon icon="ListIcon" />
								</b-button>
							</div>
						</b-td>
					</b-tr>
					<b-tr>
						<b-td class="text-center" colspan="7" v-if="rows.length == 0">
							No hay registros para mostrar
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
		<ManageDiagnosticModal
			:show="manageDiagnostic.show"
			:info="manageDiagnostic.info"
			@closing="manageDiagnostic.show = false"
			@refresh="getRows"
		/>
		<ManageTreatmentModal
			:show="manageTreatment.show"
			:info="manageTreatment.info"
			@closing="manageTreatment.show = false"
			@refresh="getRows"
		/>
		<TrackingModal
			:show="tracking.show"
			:info="tracking.info"
			@closing="tracking.show = false"
			:is_dead="status == 2 ? true : false"
		/>
		<FinishNurseryModal
			:status="3"
			:show="finish.show"
			:info="finish.info"
			@closing="finish.show = false"
			@refresh="getRows"
		/>
		<FinishNurseryModal
			:show="cancel.show"
			:info="{ ...cancel.info }"
			:status="4"
			@closing="cancel.show = false"
			@refresh="getRows"
		/>
	</div>
</template>

<script>
import nurseryService from "@/services/nursery/default.service"

import ManageDiagnosticModal from "../components/ManageDiagnosticModal.vue"
import ManageTreatmentModal from "../components/ManageTreatmentModal.vue"
import TrackingModal from "../components/TrackingModal.vue"
import FinishNurseryModal from "../components/FinishNurseryModal.vue"

export default {
	components: { ManageDiagnosticModal, ManageTreatmentModal, TrackingModal, FinishNurseryModal },
	props: { counters: Object },
	data: () => ({
		isLoading: false,
		pagination: {
			page: 1,
			perPage: 20,
			total: 0,
			from: 0,
			to: 0,
		},
		orderBy: 1,
		order: "asc",
		search: null,
		status: 0,
		rows: [],
		//
		manageDiagnostic: { show: false, info: {} },
		manageTreatment: { show: false, info: {} },
		tracking: { show: false, info: {} },
		finish: { show: false, info: {} },
		cancel: { show: false, info: {} },
	}),
	methods: {
		changeOrder(col) {
			if (col == this.orderBy) {
				if (this.order == "asc") this.order = "desc"
				else this.order = "asc"
				this.getRows()
				return
			}
			this.orderBy = col
			this.order = "asc"
			this.getRows()
		},
		async getRows() {
			this.isLoading = true
			this.orderByDefault()
			const orderToStr = new Map()
			orderToStr.set(1, "correlative")
			orderToStr.set(2, "counter_diagnostic")
			orderToStr.set(3, "counter_treatment")
			orderToStr.set(4, "date_created")
			orderToStr.set(5, "date_updated")
			const data = await nurseryService.getChicks({
				page: this.pagination.page,
				perpage: this.pagination.perPage,
				orderby: orderToStr.get(this.orderBy),
				order: this.order,
				search: this.search,
				status_id: this.status + 1,
			})
			this.pagination.total = data.total
			this.pagination.from = data.from
			this.pagination.to = data.to
			if (this.pagination.page > data.last_page) this.pagination.page = data.last_page
			this.rows = data.data
			this.isLoading = false
			this.$emit("refresh")
		},
		resetRows() {
			this.pagination = {
				page: 1,
				perPage: 20,
				total: 0,
				from: 0,
				to: 0,
			}
			this.orderBy = 1
			this.order = "asc"
			this.search = null
			this.getRows()
		},
		async accept(row) {
			const { isConfirmed } = await this.showConfirmSwal({
				text: `Se cambiara la etapa del pollo ${row.chick.correlative} a EN PROCESO`,
			})
			if (!isConfirmed) return
			this.isLoading = true
			await nurseryService.changeChickStatus({ nursing_id: row.id, status_id: 2, is_dead: false })
			this.isLoading = false
			this.getRows()
		},
		orderByDefault() {
			if (this.status == 0) {
				this.orderBy = 4
			} else {
				this.orderBy = 5
			}
		},
	},
	created() {
		this.getRows()
	},
}
</script>
